import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfDay,
  format,
  startOfDay,
} from 'date-fns'
import { useState } from 'react'
import type { DateRange } from 'react-day-picker'

import CalendarIcon from '@/assets/icons/calendar.svg?react'
import Waterdrop from '@/assets/illustrations/waterdrop.svg?react'
import type { ChartData } from '@/data/device-data'
import useDeviceData from '@/hooks/useDeviceData'
import { cn } from '@/lib/utils'

import { Button } from '../ui/button'
import { Calendar } from '../ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { ScrollArea, ScrollBar } from '../ui/scroll-area'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'

import ECChart from './charts/ECChart'
import MoistureChart from './charts/MoistureChart'
import TempChart from './charts/TempChart'
import WaterLevelChart from './charts/WaterLevelChart'

const presets = [
  { value: -1, label: 'Last day' },
  { value: -7, label: 'Last week' },
  { value: -30, label: 'Last month' },
  { value: -365, label: 'Last year' },
]

const MIN_ZOOM = 5
const DEFAULT_ZOOM: {
  x1: string | null
  x2: string | null
} = {
  x1: null,
  x2: null,
}

type TabType = 'waterlevels' | 'temperature' | 'ec' | 'moisture'

function DeviceDataChart({
  deviceId,
  materialHeightTop,
  materialHeightBottom,
  distanceUnitSuffix,
  tempUnitSuffix,
}: {
  deviceId: string
  materialHeightTop?: number
  materialHeightBottom?: number
  distanceUnitSuffix: 'in' | 'mm'
  tempUnitSuffix: '°C' | '°F'
}) {
  const [activeTab, setActiveTab] = useState<TabType>('waterlevels')

  const [date, setDate] = useState<DateRange>(() => ({
    from: startOfDay(addDays(new Date(), -1)),
    to: endOfDay(new Date()),
  }))
  const { deviceData, isLoading } = useDeviceData(deviceId, date.from, date.to)
  const [selectValue, setSelectValue] = useState<number>(-1)

  const [filteredData, setFilteredData] = useState<ChartData[] | null>(null)
  const [zoomArea, setZoomArea] = useState(DEFAULT_ZOOM)
  const [isZooming, setIsZooming] = useState(false)

  const { chartData, hasMoistureSensor } = deviceData || {}

  const isZoomed =
    !isLoading &&
    filteredData &&
    filteredData.length > 0 &&
    filteredData?.length !== chartData?.length

  const showZoomBox =
    isZooming &&
    zoomArea.x1 &&
    zoomArea.x2 &&
    !(Math.abs(Number(zoomArea.x1) - Number(zoomArea.x2)) < MIN_ZOOM)

  function handleZoomOut() {
    setFilteredData(null)
    setZoomArea(DEFAULT_ZOOM)
  }

  // @ts-expect-error no event type export from recharts
  function handleMouseDown(e) {
    setIsZooming(true)
    const { activeLabel = '' } = e || {}
    setZoomArea({
      x1: activeLabel as string,
      x2: activeLabel as string,
    })
  }

  // @ts-expect-error no event type export from recharts
  function handleMouseMove(e) {
    if (isZooming) {
      setZoomArea((prev) => ({
        ...prev,
        x2: e.activeLabel,
      }))
    }
  }

  function handleMouseUp() {
    if (isZooming) {
      setIsZooming(false)
      let { x1, x2 } = zoomArea

      if (!x1 || !x2) return

      if (x1 > x2) [x1, x2] = [x2, x1]
      // @ts-expect-error fix types here
      if (x2 - x1 < MIN_ZOOM) return

      const dataPointsInRange = (filteredData || chartData)?.filter(
        (d) => d.timestamp >= x1 && d.timestamp <= x2,
      )

      if (typeof dataPointsInRange !== 'undefined') {
        setFilteredData(dataPointsInRange)
      }

      setZoomArea(DEFAULT_ZOOM)
    }
  }

  const showTimeOnly =
    date.from &&
    date.to &&
    Math.abs(differenceInCalendarDays(date.from, date.to)) <= 1

  const showCondensed =
    !showTimeOnly &&
    date.from &&
    date.to &&
    Math.abs(differenceInCalendarDays(date.from, date.to)) <= 14

  return (
    <div className="relative">
      <div className="m-4 flex gap-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={'outline'}
              className={cn(
                'w-[280px] justify-start text-left font-normal',
                !date && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} -{' '}
                    {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <div className="p-4">
              <Select
                value={selectValue?.toString()}
                onValueChange={(value) => {
                  handleZoomOut()
                  setDate({
                    from: startOfDay(addDays(new Date(), Number(value))),
                    to: endOfDay(new Date()),
                  })
                  setSelectValue(Number(value))
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select">
                    {selectValue ? presets[selectValue]?.label : ''}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent position="popper">
                  {presets.map((preset) => (
                    <SelectItem
                      key={preset.value}
                      value={preset.value.toString()}
                    >
                      {preset.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Calendar
              mode="range"
              selected={date}
              defaultMonth={
                date.from
                  ? addMonths(date?.from, -1)
                  : addMonths(new Date(), -1)
              }
              onDayClick={(day) =>
                setDate((prev) =>
                  prev?.to
                    ? { from: day, to: undefined }
                    : prev?.from
                      ? { from: prev?.from, to: day }
                      : { from: day, to: undefined },
                )
              }
              onSelect={(range) => {
                handleZoomOut()
                if (
                  typeof range?.from === 'object' &&
                  typeof range?.to === 'object'
                ) {
                  setDate({
                    from: startOfDay(range.from),
                    to: endOfDay(range.to),
                  })
                }
              }}
              numberOfMonths={2}
              toDate={new Date()}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        {isZoomed ? (
          <Button variant="outline" onClick={handleZoomOut}>
            Reset zoom
          </Button>
        ) : null}
      </div>
      {isLoading && !chartData ? (
        <div className="flex h-full items-center justify-center">
          <Waterdrop className="h-12 w-12 animate-bounce delay-75" />
          <Waterdrop className="h-10 w-10 animate-bounce delay-100" />
          <Waterdrop className="h-8 w-8 animate-bounce delay-150" />
        </div>
      ) : (
        <>
          {hasMoistureSensor ? (
            <Tabs
              className="m-4 rounded-full"
              value={activeTab}
              onValueChange={(val) => setActiveTab(val as TabType)}
            >
              <ScrollArea>
                <TabsList>
                  <TabsTrigger className="bg-sand" value="waterlevels">
                    Water Levels
                  </TabsTrigger>
                  <TabsTrigger value="moisture">Moisture</TabsTrigger>
                  <TabsTrigger value="ec">Electrical Conductivity</TabsTrigger>
                  <TabsTrigger value="temperature">Temperature</TabsTrigger>
                </TabsList>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
              <TabsContent value="waterlevels">
                <WaterLevelChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={distanceUnitSuffix}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                  materialHeightTop={materialHeightTop}
                  materialHeightBottom={materialHeightBottom}
                />
              </TabsContent>
              <TabsContent value="moisture">
                <MoistureChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={'%'}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                />
              </TabsContent>
              <TabsContent value="ec">
                <ECChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={'μS/cm'}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                />
              </TabsContent>
              <TabsContent value="temperature">
                <TempChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={tempUnitSuffix}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                />
              </TabsContent>
            </Tabs>
          ) : (
            <WaterLevelChart
              data={filteredData || chartData || []}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              isZoomed={Boolean(isZoomed)}
              showTimeOnly={Boolean(showTimeOnly)}
              showCondensed={Boolean(showCondensed)}
              unitSuffix={distanceUnitSuffix}
              zoomArea={zoomArea}
              showZoomBox={Boolean(showZoomBox)}
              materialHeightTop={materialHeightTop}
              materialHeightBottom={materialHeightBottom}
            />
          )}
        </>
      )}
    </div>
  )
}
export default DeviceDataChart
